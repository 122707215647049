/*@font-face {*/
  /*font-family: 'Assistant';*/
  /*src: url('https://fonts.googleapis.com/css?family=Assistant:Regular');*/
  /*font-style: normal;*/
  /*font-weight: 400;*/
/*}*/
.App {
  text-align: center;
  /*font-family: 'Assistant', serif;*/
  font-style: normal;
  font-weight: 400;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/*@media screen and (min-width: 779px) {*/
  .App-root-1 > div:first-child{
    /*margin: 0 200px;*/
    /*max-width: 750px;*/
    /*margin: auto;*/
  }  
/*}*/

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input[type=file] {
  visibility: hidden;
  position: absolute
}
